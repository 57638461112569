<template>
  <div id="view-settings-landowners">
    <div class="text-right pb-3 px-4 px-sm-0">
      <button
        class="btn btn-sm btn-primary rounded-pill px-3 font-weight-medium"
        @click="addItem(true)"
      >
        + Add landowners
      </button>
    </div>
    <div class="table-responsive bg-light p-4 rounded shadow-sm">
      <table class="table table-sm">
        <thead>
          <tr>
            <th class="fit-cell"></th>
            <th>Name</th>
            <th>Land Category</th>
            <th>Address</th>
            <th>Phone No.</th>
            <th>Bank Name</th>
            <th>Bank Account No</th>
            <th class="fit-cell"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item._id">
            <td class="fit-cell text-nowrap">
              <button
                class="btn btn-link text-decoration-none px-0"
                @click.stop="editItem(item)"
              >
                <i class="fas fa-edit"></i>
              </button>
            </td>
            <td class="align-middle">{{ item.name }}</td>
            <td class="align-middle">{{ item.land_category }}</td>
            <td class="align-middle">{{ item.address }}</td>
            <td class="align-middle">{{ item.phone_no }}</td>
            <td class="align-middle">{{ item.bank_account }}</td>
            <td class="align-middle">{{ item.bank_account_no }}</td>
            <td class="fit-cell text-nowrap">
              <button
                class="btn btn-link text-decoration-none px-0"
                @click.stop="deleteItem(item)"
              >
                <i class="fas fa-trash-alt text-danger"></i>
              </button>
            </td>
          </tr>
          <tr v-if="items.length < 1">
            <td colspan="8" class="text-center font-italic text-muted">
              No item found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <modal
      id="landownerModal"
      v-if="showModal.landowners"
      :title="`${currentLandowner ? 'Edit' : 'Add new'} landowner`"
      @toggleSpinner="toggleSpinner"
      @close="addItem"
    >
      <landowner-form :item="currentLandowner" />
    </modal>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Modal from "@/components/Modal";
import LandownerForm from "@/components/forms/Landowner";

export default {
  name: "settings-landowners-view",
  components: {
    Modal,
    LandownerForm,
  },
  data() {
    return {
      items: [],
      showModal: {
        landowners: false,
      },
      currentLandowner: null,
    };
  },
  methods: {
    addItem(result) {
      if (typeof result == "boolean") {
        this.showModal.landowners = result;
      } else if (typeof result == "object") {
        this.showModal.landowners = result.show;

        if (result.data) {
          if (this.currentLandowner) {
            const itemIndex = this.items.findIndex(
              (item) => item._id == result.data._id
            );

            if (itemIndex > -1) {
              this.$set(this.items, itemIndex, result.data);
            }
          } else {
            this.items.push(result.data);
          }
        }

        this.currentLandowner = null;
      }
    },
    editItem(item) {
      this.currentLandowner = item;

      this.addItem(true);
    },
    deleteItem(item) {
      Swal.fire({
        html: `<p>Confirm delete this item?</p><p class='font-weight-bold'>${item.name}</p>`,
        icon: "question",
        confirmButtonText: "Confirm",
        showCancelButton: true,
        buttonsStyling: false,
        reverseButtons: true,
        customClass: {
          confirmButton: "btn btn-sm btn-danger",
          cancelButton: "btn btn-sm btn-light",
          actions: "d-flex justify-content-between align-items-center",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.toggleSpinner(true);

          await this.API.del(`landowners/${item._id}`);

          this.items = this.items.filter((i) => i._id != item._id);

          this.toggleSpinner(false);
        }
      });
    },
    toggleSpinner(e) {
      this.$emit("toggleSpinner", e);
    },
    async getItems() {
      this.toggleSpinner(true);

      this.API.get("landowners?_sort=name:ASC")
        .then((retVal) => {
          this.items = retVal.data;

          this.toggleSpinner(false);

          return;
        })
        .catch(() => {
          this.toggleSpinner(false);

          return;
        });
    },
  },
  async mounted() {
    await this.getItems();
  },
};
</script>