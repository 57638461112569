<template>
  <div class="form">
    <div class="row pb-4">
      <template v-for="input in inputs">
        <component
          :is="`${input.type}-input`"
          :key="input.id"
          :input="input"
          class="col-12"
          :class="`${
            typeof input.col != undefined
              ? input.col === 0
                ? 'col-sm'
                : 'col-sm-'.concat(input.col)
              : 'col-sm-12'
          }`"
          @change="inputChanged"
        />
      </template>
    </div>
    <div
      class="pt-3 border-top d-flex justify-content-between align-items-center"
    >
      <button class="btn btn-light px-4" @click="close()">Cancel</button>
      <button class="btn btn-success px-4" @click="save()">
        <i class="fas fa-save mr-2"></i>
        Save
      </button>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/inputs/Text";
import FileInput from "@/components/inputs/File";

export default {
  name: "telco-form-component",
  props: ["telco"],
  components: {
    TextInput,
    FileInput,
  },
  data() {
    return {
      inputs: [
        {
          type: "file",
          label: "Logo",
          id: "logo",
          model: null,
          accept: "image/*",
        },
        {
          type: "text",
          label: "Name",
          id: "name",
          model: "",
        },
      ],
    };
  },
  methods: {
    close() {
      this.$parent.close();
    },
    async save() {
      this.$parent.$emit("toggleSpinner", true);

      let body = {};

      this.inputs
        .filter((input) => input.type != "file")
        .forEach((input) => {
          body[input.id] =
            typeof input.model == "string" ? input.model.trim() : input.model;
        });

      const fileInput = this.inputs.find((input) => input.type == "file");

      if (!fileInput.model) {
        body[fileInput.id] = null;
      }

      const result = await this.postTelco(body);

      if (fileInput.model && fileInput.model[0] instanceof File) {
        let formData = new FormData();

        let fileModel = {
          ref: "telco",
          refId: result._id,
          field: "logo",
          files: fileInput.model,
        };

        Object.keys(fileModel).forEach((key) => {
          if (key != "files") {
            formData.append(key, fileModel[key]);
          } else {
            fileModel[key].forEach((file) => {
              formData.append("files", file);
            });
          }
        });

        const uploadImage = await this.API.postForm("upload", formData);

        if (uploadImage.status == 200) {
          result["logo"] = uploadImage.data[0];
        }
      }

      this.$parent.$emit("toggleSpinner", false);

      this.$parent.result.data = result;

      this.$parent.close();
    },
    postTelco(body) {
      return new Promise((resolve, reject) => {
        let url = "telcos";
        let method = "post";

        if (this.telco) {
          url += `/${this.telco._id}`;
          method = "put";
        }

        this.API[method](url, body)
          .then((retVal) => {
            return resolve(retVal.data);
          })
          .catch(() => {
            this.$parent.$emit("toggleSpinner");

            return reject();
          });
      });
    },
    inputChanged(e) {
      let input = this.inputs.find((i) => i.id == e.id);

      input.model = e.model;
    },
  },
  mounted() {
    if (this.telco) {
      this.inputs = this.inputs.map((input) => {
        if (this.telco[input.id]) {
          if (input.type != "file") {
            if (typeof this.telco[input.id] == "object") {
              input.model = this.telco[input.id]._id;
            } else {
              input.model = this.telco[input.id];
            }
          } else {
            if (this.telco[input.id] && this.telco[input.id].url) {
              input.model = this.telco[input.id];
            }
          }
        }

        return input;
      });
    }
  },
};
</script>