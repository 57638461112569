<template>
  <div class="form">
    <div class="row pb-4">
      <template v-for="input in inputs">
        <component
          :is="`${input.type}-input`"
          :key="input.id"
          :input="input"
          :class="`${
            typeof input.col != 'undefined'
              ? input.col === 0
                ? 'col'
                : 'col-'.concat(input.col)
              : 'col-12'
          }`"
          @change="inputChanged"
        />
      </template>
    </div>
    <div
      class="pt-3 border-top d-flex justify-content-between align-items-center"
    >
      <button class="btn btn-light px-4" @click="close()">Cancel</button>
      <button class="btn btn-success px-4" @click="save()">
        <i class="fas fa-save mr-2"></i>
        Save
      </button>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/inputs/Text";
import TextareaInput from "@/components/inputs/Textarea";

export default {
  name: "landowner-form-component",
  props: ["item"],
  components: {
    TextInput,
    TextareaInput,
  },
  data() {
    return {
      inputs: [
        {
          type: "text",
          label: "Name",
          id: "name",
          model: null,
        },
        {
          type: "text",
          label: "Land Category",
          id: "land_category",
          model: null,
          col: 6,
        },
        {
          type: "text",
          label: "Phone No.",
          id: "phone_no",
          model: null,
          col: 6,
        },
        {
          type: "textarea",
          label: "Address",
          id: "address",
          model: null,
        },
        {
          type: "text",
          label: "Bank Name",
          id: "bank_account",
          model: null,
          col: 6,
        },
        {
          type: "text",
          label: "Bank Account No.",
          id: "bank_account_no",
          model: null,
          col: 6,
        },
      ],
    };
  },
  methods: {
    close() {
      this.$parent.close();
    },
    async save() {
      this.$parent.$emit("toggleSpinner", true);

      let body = {};

      this.inputs.forEach((input) => {
        body[input.id] =
          typeof input.model == "string" ? input.model.trim() : input.model;
      });

      const result = await this.postLandowner(body);

      this.$parent.$emit("toggleSpinner", false);

      this.$parent.result.data = result;

      this.$parent.close();
    },
    postLandowner(body) {
      return new Promise((resolve, reject) => {
        let url = "landowners";
        let method = "post";

        if (this.item) {
          url += `/${this.item._id}`;
          method = "put";
        }

        this.API[method](url, body)
          .then((retVal) => {
            return resolve(retVal.data);
          })
          .catch(() => {
            this.$parent.$emit("toggleSpinner");

            return reject();
          });
      });
    },
    inputChanged(e) {
      let input = this.inputs.find((i) => i.id == e.id);

      input.model = e.model;
    },
  },
  mounted() {
    if (this.item) {
      this.inputs = this.inputs.map((input) => {
        if (this.item[input.id]) {
          if (typeof this.item[input.id] == "object") {
            input.model = this.item[input.id]._id;
          } else {
            input.model = this.item[input.id];
          }
        }

        return input;
      });
    }
  },
};
</script>