<template>
  <div class="form">
    <div class="row pb-4">
      <text-input :input="input" class="col-12" @change="inputChanged" />
    </div>
    <div
      class="pt-3 border-top d-flex justify-content-between align-items-center"
    >
      <button class="btn btn-light px-4" @click="close()">Cancel</button>
      <button class="btn btn-success px-4" @click="save()">
        <i class="fas fa-save mr-2"></i>
        Save
      </button>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/inputs/Text";

export default {
  name: "contractor-form-component",
  props: ["item"],
  components: {
    TextInput,
  },
  data() {
    return {
      input: {
        type: "text",
        label: "Name",
        id: "name",
        model: null,
      },
    };
  },
  methods: {
    close() {
      this.$parent.close();
    },
    async save() {
      this.$parent.$emit("toggleSpinner", true);

      let body = {};

      body[this.input.id] =
        typeof this.input.model == "string"
          ? this.input.model.trim()
          : input.model;

      const result = await this.postUrl(body);

      this.$parent.$emit("toggleSpinner", false);

      this.$parent.result.data = result;

      this.$parent.close();
    },
    postUrl(body) {
      return new Promise((resolve, reject) => {
        let url = "contractors";
        let method = "post";

        if (this.item) {
          url += `/${this.item._id}`;
          method = "put";
        }

        this.API[method](url, body)
          .then((retVal) => {
            return resolve(retVal.data);
          })
          .catch(() => {
            this.$parent.$emit("toggleSpinner");

            return reject();
          });
      });
    },
    inputChanged(e) {
      this.input.model = e.model;
    },
  },
  mounted() {
    if (this.item && this.item[this.input.id]) {
      this.input.model = this.item[this.input.id];
    }
  },
};
</script>