<template>
  <div id="view-settings" class="pt-4 pt-md-5 pb-3 px-0 px-sm-3">
    <div class="row mx-0 layout-with-sidebar">
      <div class="col-12 order-2 order-md-3 sidebar">
        <div class="px-0 pl-sm-3 pr-sm-3 pr-md-0">
          <div class="custom-card">
            <div class="custom-card-header">
              <div>
                <i class="fas fa-cogs mr-2"></i>
                Settings
              </div>
            </div>
            <div class="list-group">
              <button
                type="button"
                v-for="link in links"
                :key="link.type"
                class="list-group-item text-left px-5"
                :class="{
                  'active font-weight-medium': currentType == link.type,
                }"
                @click="currentType = link.type"
              >
                <small
                  ><i
                    class="fas fa-circle mr-2"
                    :class="{ 'text-muted': currentType != link.type }"
                  ></i
                ></small>
                {{ link.name }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 order-4 content">
        <div class="px-0 pl-sm-3 pr-sm-3 pl-md-0">
          <component :is="currentType" @toggleSpinner="toggleSpinner" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Contractors from "@/views/Setting/Contractors";
import PaymentModes from "@/views/Setting/PaymentModes";
import States from "@/views/Setting/States";
import Suppliers from "@/views/Setting/Suppliers";
import Landowners from "@/views/Setting/Landowners";
import Telcos from "@/views/Setting/Telcos";
import StructureTypes from "@/views/Setting/StructureTypes";
import ApparatusTypes from "@/views/Setting/ApparatusTypes";

export default {
  name: "settings-view",
  components: {
    Contractors,
    PaymentModes,
    States,
    Suppliers,
    Landowners,
    Telcos,
    StructureTypes,
    ApparatusTypes,
  },
  data() {
    return {
      currentType: null,
      links: [
        {
          name: "Contractors",
          type: "contractors",
        },
        {
          name: "Landowners",
          type: "landowners",
        },
        {
          name: "Payment Modes",
          type: "payment-modes",
        },
        {
          name: "States",
          type: "states",
        },
        {
          name: "Suppliers",
          type: "suppliers",
        },
        {
          name: "Telcos",
          type: "telcos",
        },
        {
          name: "Structure Types",
          type: "structure-types",
        },
        {
          name: "Apparatus Types",
          type: "apparatus-types",
        },
      ],
    };
  },
  methods: {
    toggleSpinner(e) {
      this.$parent.toggleSpinner(e);
    },
  },
  mounted() {
    this.currentType = this.links[0].type;
  },
};
</script>